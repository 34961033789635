<template>
    <WindowLayout :title="title">
        <div class="row" v-if="isReady">
            <div class="darmin card">
                <ConsultaSetupInfo v-model="state.setup" :isReadOnly="state.id ? true : false"></ConsultaSetupInfo>
            </div>
            <div class="darmin card">
                <PacienteInfo v-model="state.paciente" @setInstitucion="setInstitucion"
                    :isReadOnly="state.id ? true : false"></PacienteInfo>
            </div>
            <div class="darmin card">
                <DoctoresInfo v-model="state.doctor" :isReadOnly="disabled" @onChange="handleSelectDoctor"
                    :maxDoctores="2"></DoctoresInfo>
            </div>
            <div class="darmin card">
                <div class="card-body theme-form">
                    <div class="row">
                        <div class="col-12 d-flex mb-4">
                            <label class="col-sm-3 col-form-label">Entidad</label>
                            <div class="col-sm-9">
                                <InstitucionAutocomplete :class="{ 'is-invalid': v.institucion.$error }"
                                    v-model="state.institucion" :disabled="disabled" />
                                <div class="invalid-feedback" v-if="v.institucion.$error">
                                    {{ v.institucion.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex mb-4">
                            <label class="col-md-3 col-form-label">Fecha: </label>
                            <div class="col-md-9">
                                <DateInput v-model="state.date" :onlyRead="disabled" />
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Motivo de consulta</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.motivo_consulta"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Enfermedad Actual</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.examen_fisico"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 mb-4">
                            <OjosForm v-model="state.data.ojos" :disabled="disabled" />
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Biomicroscopia</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.biomicroscopia"
                                :disabled="disabled"></textarea>
                        </div>
                        <FormulaGafasForm class="col-12 mb-2" v-model="state.data.formula_gafas" :disabled="disabled" />
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Examen Motor</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.examen_motor"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Gonioscopia</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.gonioscopia"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Lentes de contacto</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.lentes_contacto"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 col-xl-6 mb-4">
                            <label class="col-form-label">Fondo de Ojo</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.fondo_ojo"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 mb-4" v-if="true">
                            <label class="col-form-label">Tipos de Patologia</label>
                            <div class="p-2 d-flex border m-checkbox-inline">
                                <div class="row g-3">
                                    <div class="col-sm-auto">
                                        <label class="me-1" for="">Paciente Sano</label>
                                        <div class="">
                                            <SiNoCheckInput v-model="state.data.paciente_sano" />
                                        </div>
                                    </div>
                                    <div class="col-sm-auto">
                                        <label class="me-1" for="">Enfermedad General o Comun</label>
                                        <div class="">
                                            <SiNoCheckInput v-model="state.data.enfermedad_general" />
                                        </div>
                                    </div>
                                    <div class="col-sm-auto">
                                        <label class="me-1" for="">Accidente de Trabajo</label>
                                        <div class="">
                                            <SiNoCheckInput v-model="state.data.accidente_trabajo" />
                                        </div>
                                    </div>
                                    <div class="col-sm-auto">
                                        <label class="me-1" for="">Accidente fuera del Trabajo</label>
                                        <div class="">
                                            <SiNoCheckInput v-model="state.data.accidente_fuera_trabajo" />
                                        </div>
                                    </div>
                                    <div class="col-sm-auto">
                                        <label class="me-1" for="">Enfermedad Profesional</label>
                                        <div class="">
                                            <SiNoCheckInput v-model="state.data.enfermedad_profesional" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Diagnostico RIPS -CIE10</label>
                            <DiagnosticosRipsForm v-model="state.data.diagnostico_rips" :disabled="disabled" />
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Diagnostico Medico</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.diagnosticos_medicos"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Observaciones</label>
                            <textarea class="form-control border" rows="4" v-model="state.data.evolucion_observacion"
                                :disabled="disabled"></textarea>
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Ayudas Diagnosticas</label>
                            <AyudasDiagnosticasForm v-model="state.data.ayudas_diagnosticas" :disabled="disabled" />
                        </div>
                        <div class="col-12 mb-4">
                            <label class="col-form-label">Plan / Recomendaciones</label>
                            <RecomendacionesForm v-model="state.data.plan_recomendaciones" :disabled="disabled" />
                        </div>
                    </div>
                </div>
            </div>

            <!--Evoluciones-->
            <div class="darmin card" v-if="disabled">
                <div class="card-header">
                    <h5 class="mb-0">Evolución</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <EvolucionForm v-model="state.evoluciones" @onChange="handleNewEvolucion"
                                @onRender="handleRenderEvolution" />
                        </div>
                    </div>
                </div>
            </div>
            <!--/Evoluciones-->
            <!--Renders-->
            <ConsultaRenderedFiles v-if="state.documentStatus >= 2" :files="state.renders"
                :disabled="state.documentStatus === 3" @onRemoveRender="HanlderRemoveRender"
                @onChange="handleAction('save')" />
            <!--/Renders-->

            <!--Render Buttons Bar-->
            <div class="darmin card header-card fixed-row"
                v-if="state.id && state.documentStatus === 2 && consultaSetup">
                <div class="row">
                    <RenderButtonsBar :setup="consultaSetup" @onClick="handleClickRenderBar" />
                </div>
            </div>
            <!--/Render Buttons Bar-->

            <Observaciones :observaciones="state.observaciones" @onAddObservacion="handleAddNewObservacion"
                @onDeleteObservacion="handledeleteNewObservacion" />
            <!--Barra de Accion-->
            <div class="darmin card header-card border">
                <div class="row">
                    <div class="col-12 text-end">
                        <div class="btn btn-warning ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 2 && !state.renders.length"
                            @click="handleAction('goback')">
                            <i class="far fa-edit"></i>
                            Editar
                        </div>
                        <div class="btn btn-success ms-3 mb-1" v-if="state.id && state.documentStatus === 3"
                            @click="handleAction('resend')">
                            <i class="far fa-file-import"></i>
                            Reenviar
                        </div>
                        <div class="btn btn-success ms-3 mb-1"
                            v-if="state.id && state.documentStatus === 2 && !state.renders.length"
                            @click="handleAction('build_docs')">
                            <i class="far fa-file-import"></i>
                            Generar Documentos
                        </div>
                        <a class="btn btn-success ms-3 mb-1" target="_blank"
                            v-if="state.id && state.documentStatus === 2 && state.renders.length"
                            @click="handleAction('publish')">
                            <i class="far fa-file-alt"></i>
                            Publicar
                        </a>
                        <a class="btn btn-warning ms-3 mb-1" target="_blank"
                            v-if="state.id && state.documentStatus === 3" @click="handleViewDocuments">
                            <i class="far fa-file-import"></i>
                            Ver Documentos
                        </a>
                        <div class="btn btn-success ms-3 mb-1" v-if="state.id && state.documentStatus === 1"
                            @click="handleAction('close')">
                            <i class="far fa-file-import"></i>
                            Finalizar
                        </div>
                        <div class="btn btn-primary ms-3 mb-1" v-if="state.id && state.documentStatus === 1"
                            @click="handleAction('save')">
                            <i class="far fa-save"></i>
                            Guardar
                        </div>
                        <div class="btn btn-primary ms-3 mb-1" v-if="!state.id" @click="handleAction('create')">
                            <i class="far fa-save"></i>
                            Crear
                        </div>
                        <div class="btn btn-primary ms-3 mb-1" v-if="!state.id" @click="handleAction('create_view')">
                            <i class="far fa-save"></i>
                            Crear y mostrar
                        </div>
                    </div>
                </div>
            </div>
            <!--/Barra de Accion-->
        </div>

        <pre v-if="env === 'development'">{{ JSON.stringify(state, null, 4) }}</pre>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onBeforeMount, onMounted, reactive, ref } from 'vue';
import WindowLayout from '@/layouts/Window.layout.vue';
import DateInput from '@/components/form/DateInput.vue';
import ConsultaSetupInfo from '@/modules/Care/ConsultaSetup/components/ConsultaSetupInfo.vue';
import PacienteInfo from '@/modules/Care/Pacientes/components/PacienteInfo.vue';
import DoctoresInfo from '@/modules/Care/Doctores/components/DoctoresInfo.vue';
import InstitucionAutocomplete from '@/modules/Care/Instituciones/components/InstitucionAutocomplete.vue';
import EvolucionForm from './components/EvolucionForm.vue';
import useConsultaForm from './services/useConsultaForm';
import useConsulta from './services/useConsulta';
import useConsultaSetup from '../ConsultaSetup/services/useConsultaSetup';
import OjosForm from './components/oftalmologia/OjosForm.vue';
import DiagnosticosRipsForm from './components/DiagnosticosRipsForm.vue';
import AyudasDiagnosticasForm from './components/AyudasDiagnosticasForm.vue';
import RecomendacionesForm from './components/RecomendacionesForm.vue';
import FormulaGafasForm from './components/oftalmologia/FormulaGafasForm.vue';
import ConsultaRenderedFiles from './components/ConsultaRenderedFiles.vue';
import RenderButtonsBar from './components/RenderButtonsBar.vue';
import SiNoCheckInput from './components/SiNoCheckInput.vue';
import Observaciones from '../Components/Observaciones.vue';

export default {
    name: 'OftalmologiaForm',
    // eslint-disable-next-line object-curly-newline
    components: {
        WindowLayout,
        DateInput,
        ConsultaSetupInfo,
        PacienteInfo,
        DoctoresInfo,
        OjosForm,
        DiagnosticosRipsForm,
        AyudasDiagnosticasForm,
        RecomendacionesForm,
        FormulaGafasForm,
        ConsultaRenderedFiles,
        EvolucionForm,
        RenderButtonsBar,
        SiNoCheckInput,
        Observaciones,
        InstitucionAutocomplete,
    },
    props: {
        title: { type: String, default: 'Consulta' },
        headerTitle: { type: String, default: 'Nueva consulta' },
        item: { type: Object, required: false },
        action: { required: false },
        citaId: String,
    },
    setup(props) {
        const isReady = ref(false);
        const consultaService = useConsulta();
        // eslint-disable-next-line object-curly-newline
        const {
            state,
            setState,
            v,
            handleAction,
            disabled,
            handleAddNewObservacion,
            handledeleteNewObservacion,
            setInstitucion,
            formType,
        } = useConsultaForm(consultaService);
        // eslint-disable-next-line no-unused-vars
        const { consultaSetup, getConsultaSetup } = useConsultaSetup();

        onBeforeMount(() => {
            state.setup = process.env.VUE_APP_GASTROENTEROLOGIA_TYPE;
            formType.value = 'VUE_APP_GASTROENTEROLOGIA_TYPE';
            state.data = reactive({
                motivo_consulta: '',
                examen_fisico: '',
                ojos: {},
                examen_motor: '',
                gonioscopia: '',
                biomicroscopia: '',
                lentes_contacto: '',
                fondo_ojo: '',
                diagnosticos_medicos: '',
                paciente_sano: 'No',
                enfermedad_general: 'No',
                accidente_trabajo: 'No',
                accidente_fuera_trabajo: 'No',
                enfermedad_profesional: 'No',
                diagnostico_rips: [],
                evolucion_observacion: '',
                ayudas_diagnosticas: [],
                plan_recomendaciones: [],
                formula_gafas: {},
            });
        });

        onMounted(async () => {
            if (props.item) {
                if (props.item.id) {
                    await Promise.all([consultaService.getConsulta(props.item.id)]);
                    if (consultaService.consulta.value) {
                        if (props.action && props.action === 'observacion') {
                            // eslint-disable-next-line object-curly-newline, operator-linebreak
                            const { paciente, doctor, specialty, data } =
                                consultaService.consulta.value;
                            consultaService.consulta.value = {
                                paciente,
                                doctor,
                                specialty,
                                data,
                            };
                        }
                        console.log('setState', consultaService.consulta.value.data);
                        setState(consultaService.consulta.value);
                        await getConsultaSetup(consultaService.consulta.value.setup);
                    }
                } else {
                    const tempState = { ...state };
                    if (props.item.paciente) {
                        tempState.paciente = props.item.paciente;
                    }
                    if (props.item.doctor) {
                        tempState.doctor = props.item.doctor;
                    }
                    setState(tempState);
                }
                if (props.item.citaId) {
                    state.citaId = props.item.citaId;
                }
            }
            isReady.value = true;
        });

        const handleSelectDoctor = (specialties) => {
            state.specialty = specialties;
        };

        const handleNewEvolucion = async () => {
            await handleAction('render_evolucion');
        };

        const handleViewDocuments = () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            window.open(
                // eslint-disable-next-line comma-dangle
                `${process.env.VUE_APP_DARMIN_RESULTADOS_URL}/documento/historia/${state.key}`,
                // eslint-disable-next-line comma-dangle
                '_blank'
            );

        const handleClickRenderBar = (templateId) => {
            console.log('click');
            handleAction('render_template', { templateId });
        };

        const handleRenderEvolution = async (index) => {
            await handleAction('render_evolucion', { index });
        };

        const HanlderRemoveRender = (renderId) => {
            handleAction('remove_render', { renderId });
        };

        return {
            state,
            disabled,
            v,
            isReady,
            handleAction,
            handleSelectDoctor,
            handleNewEvolucion,
            handleRenderEvolution,
            handleViewDocuments,
            handleClickRenderBar,
            consultaSetup,
            HanlderRemoveRender,
            env: process.env.NODE_ENV,
            handleAddNewObservacion,
            handledeleteNewObservacion,
            setInstitucion,
        };
    },
};
</script>

<style scoped lang="scss">
.fixed-row {
    position: sticky;
    bottom: 0;
    z-index: 999;
}
</style>
